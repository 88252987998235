import React from 'react'
import selling1 from "../../assets/discounts_image/Blue and White Minimalist Coupon Discount Promo Denim Instagram Post (3).png"
import selling2 from "../../assets/discounts_image/Anneca sale (1).png"
import person1 from "../../assets/discounts_image/Anneca sale (5).png"
import person2 from "../../assets/discounts_image/2.png"
import person3 from "../../assets/discounts_image/3.png"
import person4 from "../../assets/discounts_image/4.png"
const Photosection = () => {
    return (
        <div className='photosection'>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex flex-wrap">
                        <div className="col-12 col-md-3">
                            <img src={selling1} className='box_class' alt="" />
                        </div>
                        <div className="col-12 col-md-6 centersmallimage my-4 my-lg-0 d-flex flex-wrap justify-content-between justify-content-md-center">
                            <div className="col-12 col-md-12 h-100 ">
                                <div className="photosection-img h-100 me-0 me-md-2 me-lg-2  ms-0 ms-md-2">
                                    <img src={person1} alt="" />
                                </div>
                            </div>
                           

                        </div>
                        <div className="col-12 col-md-3">
                            <img src={selling2} className='box_class' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Photosection
